<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <v-card class="radius-card px-3" :loading="loader">
        <v-card-title>
          List Item
          <v-spacer></v-spacer>
          <v-btn color="purple" dark @click="dialog = true"
            ><v-icon class="mr-2">mdi-plus</v-icon> Add item</v-btn
          >
        </v-card-title>

        <div v-if="listMerch">
          <v-data-table
            :headers="headers"
            :items="listMerch.data"
            class="elevation-0 my-5"
          >
            <template v-slot:[`item.image`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    depressed
                    color="blue"
                    dark
                    fab
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="viewImg(item.gambar)"
                  >
                    <v-icon dark small> mdi-image </v-icon>
                  </v-btn>
                </template>
                <span>View Image</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <div class="d-flex">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      depressed
                      color="blue"
                      dark
                      fab
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      @click="previewUpdate(item)"
                    >
                      <v-icon dark small> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Item</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      depressed
                      color="red"
                      dark
                      fab
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(item.id)"
                    >
                      <v-icon dark small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Item</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2"></div>
        </div>
      </v-card>
    </div>
    <!-- DIALOG CREATE -->
    <v-dialog v-model="dialog" scrollable max-width="500">
      <v-card>
        <v-toolbar dense flat>
          <h3>Add Item</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="dialog = false"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="py-2">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                label="Nama Barang"
                v-model="judul"
                required
                dense
                solo
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                label="Harga"
                v-model="harga"
                type="number"
                required
                dense
                solo
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                label="Deskripsi singkat"
                v-model="deskripsi"
                required
                dense
                solo
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="mdi-menu-down">
                    Publish Link
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(item, idx) in olshop" :key="idx">
                      <v-text-field
                        :label="item.name"
                        v-model="item.link"
                        required
                        dense
                      ></v-text-field>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-file-input
                label="Gambar"
                hide-details="auto"
                v-model="objImage"
                solo
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple" dark @click="addItem" v-if="!loaderAct"
            ><v-icon class="mr-2">mdi-plus</v-icon> Save</v-btn
          >
          <v-btn color="purple" dark v-if="loaderAct">Loading...</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG UPDATE ITEM -->
    <v-dialog v-model="dialogUpdate" scrollable max-width="500">
      <v-card>
        <v-toolbar dense flat>
          <h3>Update Item</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="dialogUpdate = false"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="py-2" v-if="dataItem">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                label="Nama Barang"
                v-model="dataItem.judul"
                required
                dense
                solo
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                label="Harga"
                v-model="dataItem.harga"
                type="number"
                required
                dense
                solo
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                label="Deskripsi singkat"
                v-model="dataItem.deskripsi"
                required
                dense
                solo
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="mdi-menu-down">
                    Publish Link
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(item, idx) in filledOlshop" :key="idx">
                      <v-text-field
                        :label="item.name"
                        v-model="item.link"
                        required
                        dense
                      ></v-text-field>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-file-input
                label="Gambar"
                hide-details="auto"
                v-model="objImage"
                solo
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple" dark @click="updateItem" v-if="!loaderAct"
            ><v-icon class="mr-2">mdi-plus</v-icon> Update</v-btn
          >
          <v-btn color="purple" dark v-if="loaderAct">Loading...</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG VIEW IMAGE -->
    <v-dialog v-model="dialogImage" scrollable max-width="600">
      <v-card>
        <v-toolbar dense flat>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="dialogImage = false"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="py-2" v-if="imgViewer">
          <div class="d-flex justify-center">
            <img
              :src="`${env}/merchant/upload/${imgViewer}`"
              height="400px"
              alt=""
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "listMerchantAdmin",
  computed: {
    ...mapState({
      listMerch: state => state.merchandise.listMerchAdmin,
      role: state => state.role,
      env: state => state.API_URL
    })
  },
  data() {
    return {
      search: "",
      loader: false,
      loaderAct: false,
      dialog: false,
      dialogStatus: false,
      dialogImage: false,
      dialogUpdate: false,
      dataItem: null,
      judul: "",
      deskripsi: "",
      harga: "",
      objImage: null,
      imgViewer: null,
      loading: false,
      link: [],
      filledOlshop: [],
      olshop: [
        {
          name: "Shopee",
          link: "",
          img: "https://dev-pampsi.gamifindo.com/images/shopee.png"
        },
        {
          name: "Tokopedia",
          link: "",
          img: "https://dev-pampsi.gamifindo.com/images/tokopedia.png"
        },
        {
          name: "Bukalapak",
          link: "",
          img: "https://dev-pampsi.gamifindo.com/images/bukalapak.png"
        },
        {
          name: "Lazada",
          link: "",
          img: "https://dev-pampsi.gamifindo.com/images/lazada.png"
        }
      ],

      headers: [
        { text: "ID", value: "id" },
        { text: "NAME", value: "judul" },
        { text: "HARGA", value: "harga" },
        { text: "DESKRIPSI", value: "deskripsi", sortable: false },
        { text: "IMAGE", value: "image", sortable: false },
        { text: "ACTION", value: "action", align: "start", sortable: false }
      ]
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    checkLink() {
      this.olshop.forEach(item => {
        if (item.link !== "") {
          console.log(item);
        }
      });
    },
    fetch() {
      let data = {
        page: this.page,
        per_page: this.perPage,
        search: this.search
      };
      this.loader = true;
      this.$store
        .dispatch("merchandise/listMerchandiseAdmin", data)
        .then(() => {
          this.loader = false;
        });
    },
    previewUpdate(item) {
      this.dataItem = item;
      let link = JSON.parse(item.link);
      let nullableLink = [];
      if (link) {
        nullableLink = this.olshop.filter(
          ({ name: a }) => !link.some(({ name: b }) => a === b)
        );
        this.filledOlshop = [...nullableLink, ...link];
      } else {
        this.filledOlshop = this.olshop;
      }
      this.dialogUpdate = true;
    },
    addItem() {
      this.loaderAct = true;
      let data = new FormData();
      let link = [];
      this.olshop.forEach(item => {
        if (item.link !== "") {
          link.push(item);
        }
      });
      data.append("judul", this.judul);
      data.append("harga", this.harga);
      data.append("deskripsi", this.deskripsi);
      data.append("objImage", this.objImage);
      if (link.length) {
        data.append("link", JSON.stringify(link));
      }
      this.$store
        .dispatch("merchandise/createMerc", data)
        .then(data => {
          console.log(data);
          this.loaderAct = false;
          if (data.data.status == "success") {
            this.judul = "";
            this.harga = "";
            this.deskripsi = "";
            this.objImage = "";
            this.olshop.forEach(el => {
              el.link = "";
            });
            this.fetch();
            this.dialog = false;
            Swal.fire({
              title: data.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true
            });
          } else {
            Swal.fire({
              title: data.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true
            });
          }
        })
        .catch(err => {
          this.loaderAct = false;
          console.log(err);
          Swal.fire({
            title: err.message,
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true
          });
        });
    },
    updateItem() {
      this.loaderAct = true;
      let link = [];
      this.filledOlshop.forEach(item => {
        if (item.link !== "") {
          link.push(item);
        }
      });
      console.log(link);
      let data = new FormData();
      data.append("id", this.dataItem.id);
      data.append("judul", this.dataItem.judul);
      data.append("harga", this.dataItem.harga);
      data.append("deskripsi", this.dataItem.deskripsi);
      if (this.objImage) {
        data.append("objImage", this.objImage);
      }
      if (link.length) {
        data.append("link", JSON.stringify(link));
      }
      this.$store
        .dispatch("merchandise/updateMerch", data)
        .then(data => {
          console.log(data);
          this.loaderAct = false;
          if (data.data.status == "success") {
            this.dataItem = null;
            this.objImage = null;
            this.fetch();
            this.dialogUpdate = false;
            Swal.fire({
              title: data.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true
            });
          } else {
            Swal.fire({
              title: data.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true
            });
          }
        })
        .catch(err => {
          this.loaderAct = false;
          console.log(err);
          Swal.fire({
            title: err.message,
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true
          });
        });
    },

    viewImg(data) {
      this.imgViewer = data;
      this.dialogImage = true;
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          let data = {
            id: id
          };
          this.$store
            .dispatch("merchandise/deleteMerch", data)
            .then(data => {
              this.loader = false;
              console.log(data);
              this.fetch();
              Swal.fire({
                icon: "success",
                title: data.data.message,
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            })
            .catch(err => {
              this.loader = false;
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "error",
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            });
        }
      });
    }
  }
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
